import React from 'react';

import Entries from '../containers/Entries';
import SiteTitle from '../containers/SiteTitle';
import Layout from '../layouts';

const BlogIndex = ({ location }) => (
  <Layout location={location}>
    <SiteTitle />
    <Entries />
  </Layout>
);

export default BlogIndex;
